


























































































































import { computed, defineComponent, reactive } from "@vue/composition-api";
import useAuthPermission from "@/use/authPermissions";

export default defineComponent({
  components: {
    ALogo: () => import("@/components/atoms/a-logo.vue"),
    ALanguageSwitch: () => import("@/components/atoms/a-language-switch.vue"),
  },

  setup(_, { root }) {
    const state = reactive({
      user: computed(() => root.$store.getters["user/getData"]),
    });

    const { siemensReports } = useAuthPermission({ root });

    const hasAccessTo = (permission: string) =>
      root.$store.getters["user/hasAccessTo"](permission);

    const logout = () => {
      root.$store.dispatch("user/logout").then(() => {
        root.$router.push({ name: "auth" });
      });
    };

    return { state, logout, hasAccessTo, siemensReports };
  },
});
